import { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { DepartmentType } from "../domain/department";
import { Education, EducationPlan, EducationTicketOption } from "../domain/education";
import { FunctionCategoryValue } from "../domain/function-category";
import User, { CreditHistory } from "../domain/user";
import http from "./http";

export const getUser = () => {
  return http.get<User>("/users/current").then(({ data }: AxiosResponse<User>) => data);
};

export const assignCredits = (userId: string, credits: number, year: number, reason: string) => {
  return http.post(`/users/${userId}/credits`, {
    credits,
    year,
    reason
  });
};

export const enroll = (user: User, education: Education, recupDays: number, ticketOption?: EducationTicketOption) => {
  return http.post(`/users/${user.uuid}/plans/${education.year}/education`, {
    educationId: education.uuid,
    recupUnits: recupDays,
    ticketOption: ticketOption?.name
  });
};

export const enrollUsers = (userIds: string[], education: Education, ticketOption: string) => {
  return http.post(`/users/plans/education`, {
    educationId: education.uuid,
    year: education.year,
    users: userIds,
    ticketOption
  });
};

export const getCurrentEducationPlan = (user: User) => {
  return http
    .get<EducationPlan>(`/users/${user.uuid}/plans/current`)
    .then(({ data }: AxiosResponse<EducationPlan>) => data);
};

export const getEducationPlan = (user: User, year: number) => {
  return http
    .get<EducationPlan>(`/users/${user.uuid}/plans/${year}`)
    .then(({ data }: AxiosResponse<EducationPlan>) => data);
};

export const getCredits = (user: User, year = new Date().getFullYear()) => {
  return http
    .get<CreditHistory>(`/users/${user.uuid}/credits?year=${year}`)
    .then(({ data }: AxiosResponse<CreditHistory>) => data);
};

export const hasRole = (user: User | undefined, roles: string[]) => {
  if (roles.length === 0) {
    return true;
  }
  return user && roles.filter((role) => user.roles.includes(role)).length > 0;
};

export const searchUsers = (department?: DepartmentType, query?: string) => {
  let url = "/users/search?";
  if (department) {
    url += `department=${department}&`;
  }
  if (query) {
    url += `wildcard=${query}`;
  }
  return http.get<User[]>(url).then(({ data }: AxiosResponse<User[]>) => data);
};

export const updateFunctionCategory = (consultantId: string, functionCategory: FunctionCategoryValue) => {
  return http.put(`/users/${consultantId}/functioncategory`, {
    functionCategory
  });
};

export const getFunctionLevelCreditOverview = () => {
  return http
    .get<{ [key: string]: number }>(`/users/functioncategorycreditoverview`)
    .then(({ data }: AxiosResponse<{ [key: string]: number }>) => data);
};

export const assignInitialCredits = (department: DepartmentType, year: number) => {
  return http.post(`/users/credits/${department}/${year}`);
};

export const deleteEducationFromEducationPlan = (
  userId: string | undefined,
  year: number,
  educationId: string,
  ticketOption: string
) => {
  return http.delete(
    "/users/" + userId + "/plans/" + year + "/education/" + educationId + "/" + encodeURIComponent(ticketOption)
  );
};

export const deleteConsultantsEducation = (
  userId: string | undefined,
  year: number,
  educationId: string,
  ticketOption: string
) => {
  return http.delete(`/users/plans/education`, {
    data: {
      users: [userId],
      year,
      educationId,
      ticketOption
    }
  });
};

export const getUserCredits = async (user?: User) => {
  if (user) {
    const educationPlan = await getEducationPlan(user, new Date().getFullYear());
    if (!!educationPlan) {
      return educationPlan;
    } else {
      return {
        assignedCredits: 0,
        availableCredits: 0,
        year: new Date().getFullYear(),
        status: "",
        educationList: []
      };
    }
  }
};

export const refreshToken = () => {
  return http.post(`/token/refresh`, {
    refreshToken: getCookie(REFRESH_TOKEN_COOKIE)
  });
};

export const bookmark = (userId: string, educationId: string) =>
  http.post(`/users/${userId}/bookmarks`, { educationId });

export const removeBookmark = (userId: string, educationId: string) =>
  http.delete(`/users/${userId}/bookmarks`, {
    data: {
      educationId
    }
  });

export const invalidateUser = () => {
  localStorage.removeItem("dev_userId");
  Cookies.remove(REFRESH_TOKEN_COOKIE);
  Cookies.remove(ACCESS_TOKEN_COOKIE);
};

export const getCookie = (name: string): string | undefined => {
  return Cookies.get(name);
};

export const REFRESH_TOKEN_COOKIE = "AXXES_FEP_REFRESH_TOKEN";
export const ACCESS_TOKEN_COOKIE = "AXXES_FEP_TOKEN";

export const ROLES = {
  COACH: "COACH",
  CONSULTANT: "CONSULTANT",
  HR: "HR",
  ADMIN: "ADMIN",
  ALL: ["COACH", "CONSULTANT", "HR", "ADMIN"]
};
